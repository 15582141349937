<template>
    <section>
        <div class="container">
            <div class="row">
                <div class="col-lg-6 quascade-block">
                    <header>
                        <h3>Our aim</h3>
                        <h2>Accelerating your journey</h2>
                    </header>
                    <div>
                        <p>
                            As a quantum solutions provider, Quascade accelerates your journey into the astonishment of
                            quantum
                            computing by delivering easy-to-use software components that integrate seamlessly into your
                            existing.
                        </p>
                        <p class="mb-4">
                            Get your business quantum-ready with us!
                        </p>
                        <router-link class="cta-btn" to="/contact">Contact</router-link>

                    </div>
                </div>
                <div class="col-lg-6">
                    <div class="bg-illustration">
                        <img src="@/assets/illustrations/illustration-03.png" alt="Illustration">
                    </div>
                </div>
            </div>
        </div>
    </section>
</template>

<script>
export default {
    name: 'AccelerateJourneySection'
}
</script>

<style lang="scss" scoped>
section {
    margin: 10rem 0;
}

.quascade-block {
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    header {
        h2 {
            font-size: 2.5rem;
            font-weight: 600;
        }

        h3 {
            color: #293156;
            font-size: 1.25rem;
            font-weight: 600;
        }
    }
}

.bg-illustration {
    height: 500px;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #F76400;
    overflow: hidden;

    img {
        max-width: 40%;
        animation: movein 5s ease-in infinite;
    }
}



@keyframes movein {
    0% {
        margin-left: -50rem;
    }

    50%,
    100% {
        margin-left: 100rem;
    }
}

.cta-btn {
    color: #FFF;
    background-color: #F76400;
    padding: .4rem 1.75rem;
    border-radius: 5rem;
    text-decoration: none !important;
}
</style>