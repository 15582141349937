<template>
    <section>
        <div class="container">
            <div class="row" style="margin-bottom: 5rem;">
                <div class="col-lg-12 quascade-block">
                    <header class="mb-4">
                        <h3>What</h3>
                        <h2>Our Services</h2>
                    </header>
                    <div>
                        <p class="subtitle">
                            Our cutting-edge offering centers on <span>Quantum-as-a-Service</span>, providing accessible and
                            scalable
                            quantum computing solutions tailored to our customers' unique needs, including...
                        </p>
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="col-lg-4 problem-item">
                    <img src="@/assets/icons/demos_and_tutorials.png" alt="Ready-to-use software icon">
                    <h4>READY-TO-USE SOFTWARE </h4>
                    <p>
                        Quantum software components offer versatile, pre-built solutions for various business sectors and
                        daily tasks. Easily integrated, they enable businesses to utilize quantum computing's potential
                        without deep expertise. Whether optimizing supply chains, simulating systems, or analyzing data,
                        our ready-to-use components deliver efficient solutions for diverse applications."

                    </p>
                </div>
                <div class="col-lg-4 problem-item">
                    <img src="@/assets/icons/research_and_development.png" alt="Bespoke quantum solution icon">
                    <h4>Bespoke Quantum solutions</h4>
                    <p>
                        Our team offers tailored quantum solutions, working closely with clients to meet their unique needs.
                        From crafting complex algorithms to optimizing workflows and addressing industry-specific
                        challenges, we provide personalized services for optimal integration and results. Our collaborative
                        approach and expertise in quantum computing ensure solutions that fully realize its potential.
                    </p>
                </div>
                <div class="col-lg-4 problem-item">
                    <img src="@/assets/icons/hardware_integration.png" alt="Tutorials icon">
                    <h4>Tutorials & Demos</h4>
                    <p>
                        At Quascade, we are committed to demystifying the complex world of quantum computing for our
                        customers. Our tutorials offer a clear, easy-to-follow introduction to quantum computing, suitable
                        for both experts and beginners. With step-by-step guides and expert instruction, we make
                        understanding and using this advanced technology accessible to everyone. Join us in exploring the
                        exciting future of computing.
                    </p>
                </div>
            </div>
        </div>
    </section>
</template>

<script>
export default {
    name: 'OurServicesSection'
}
</script>

<style lang="scss" scoped>
section {
    padding: 3rem 0;
    margin: 10rem 0;
}

.quascade-block {
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    header {
        h2 {
            font-size: 2.5rem;
            font-weight: 600;
        }

        h3 {
            color: #293156;
            font-size: 1.25rem;
            font-weight: 600;
        }
    }
}

.subtitle {
    font-size: 1.4rem;
}

h4 {
    max-width: 16rem;
    margin-bottom: 2rem;
}

span {
    color: #F76400;
}

.problem-item {
    display: flex;
    flex-direction: column;
    align-items: left;
    text-align: left;

    img {
        width: 4rem;
        margin-bottom: 2rem;
    }
}
</style>