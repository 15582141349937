<template>
    <section>
        <div class="container">
            <div class="row align-items-center">
                <div class="col-lg-6 quascade-block">
                    <header>
                        <h3>Apps</h3>
                        <h2>Piquasso</h2>
                    </header>
                    <div>
                        <p class="mb-4">
                            Piquasso enables photonic quantum simulations through any web browser making it accessible for
                            anyone to get started quickly.
                        </p>
                        <p class="mb-4">Besides its groundbreaking performance, Piquasso offers a
                            user-friendly interface with unique community features like sharing, publishing, and real-time
                            collaboration, setting it apart from other simulators.
                        </p>
                        <a href="https://www.piquasso.com" target='_blank' class="btn cta-btn">Learn more</a>
                    </div>
                </div>
                <div class="col-lg-6">
                    <img class="piquasso-ui-img" src="@/assets/images/piquasso.png" alt="Piquasso">
                </div>
            </div>
        </div>
    </section>

    <section>
        <div class="container">
            <div class="row">
                <div class="col-lg-6 position-relative">
                    <img class="rsa-break-demo-img" src="@/assets/images/rsa-break-demo.png" alt="RSA">
                </div>
                <div class="col-lg-6 quascade-block" style="height: 25rem;">
                    <header>
                        <h3>Demos</h3>
                        <h2>RSA break demonstration</h2>
                    </header>
                    <div>
                        <p class="mb-4">

                            Explore our demo, where we simplify the complex details of RSA encryption and illustrate its
                            vulnerability to quantum attacks. Don't wait--act now to ensure the protection of your data with
                            evolving security challenges in the new quantum world! </p>
                        <a class="btn cta-btn2" href="/rsa" target='_blank'>Learn more</a>
                    </div>
                </div>

            </div>
        </div>
    </section>
</template>

<script>
export default {
    name: 'SolutionsSection'
}
</script>

<style lang="scss" scoped>
section {
    margin: 10rem 0;
}

.quascade-block {
    height: 30rem;
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    header {
        h2 {
            font-size: 2.5rem;
            font-weight: 600;
        }

        h3 {
            color: #293156;
            font-size: 1.25rem;
            font-weight: 600;
        }
    }
}

.piquasso-ui-img,
.rsa-break-demo-img {
    max-width: 50rem;
}

.rsa-break-demo-img {
    position: absolute;
    right: 0;
}

.cta-btn,
.cta-btn:hover {
    color: #FFF !important;
    height: 2.75rem;
    background-color: #F76400 !important;
    padding: .4rem 1.75rem;
    border-radius: 5rem;
    text-decoration: none !important;
}

.cta-btn2,
.cta-btn2:hover {
    color: #15192C !important;
    height: 2.75rem;
    background-color: #2ADDBA !important;
    padding: .4rem 1.75rem;
    border-radius: 5rem;
    text-decoration: none !important;
}
</style>