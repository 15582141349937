<template>
    <section>
        <div class="container">
            <div class="row" style="margin-bottom: 5rem;">
                <div class="col-lg-12 quascade-block text-center d-flex align-items-center">
                    <header class="mb-4">
                        <h2>Meet Our Team</h2>
                    </header>
                    <div>
                        <p class="mb-5">
                            Meet the brilliant minds behind our groundbreaking work in quantum computing and beyond. Our
                            team includes accomplished Ph.D. scientists and physicists, software developers, and renowned
                            consultants who bring a wealth of experience from academia and the business world.
                        </p>
                    </div>
                    <img class="w-75" src="@/assets/images/team.png" alt="Our team">
                </div>
            </div>
        </div>
    </section>
</template>

<script>
export default {
    name: 'OurTeam'
}
</script>

<style lang="scss" scoped>
.quascade-landing-page section {
    margin: 15rem 0;
}

.quascade-block {
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    header {
        h2 {
            font-size: 2.5rem;
            font-weight: 600;
        }

        h3 {
            color: #293156;
            font-size: 1.25rem;
            font-weight: 600;
        }
    }
}

img {
    border-radius: 5px;
}
</style>