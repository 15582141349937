<template>
    <section>
        <div class="container">
            <div class="row" style="margin-bottom: 5rem;">
                <div class="col-lg-6 d-flex justify-content-center">
                    <img class="quantum-computer-img" src="@/assets/images/quantum_computer.png" alt="Quantum computer">
                </div>
                <div class="col-lg-6 quascade-block">
                    <header>
                        <h3>Why</h3>
                        <h2>Why quantum computing?</h2>
                    </header>
                    <div>
                        <p>
                            Step into the future of computing with quantum technology - a game-changer that's reshaping the
                            rules of the digital world. Quantum computing's unparalleled processing power opens doors to
                            solving complex problems at lightning speed, revolutionizing industries from cybersecurity to
                            drug discovery. <br><br>Don't miss the quantum wave - start thinking quantum now and ride the
                            tide
                            of innovation!
                        </p>
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="col-lg-4 problem-item">
                    <img src="@/assets/icons/demos_and_tutorials.png" alt="Intracable problem icon">
                    <h4>Intracable problems</h4>
                    <p>
                        Traditional computers, limited by sequential processing, struggle with complex tasks such as large
                        number factoring or drug discovery. Quantum computing introduces a significant shift, turning
                        previously unsolvable challenges into achievable tasks and enabling groundbreaking advancements
                        across various fields.
                    </p>
                </div>
                <div class="col-lg-4 problem-item">
                    <img src="@/assets/icons/hardware_integration.png" alt="Fundemental limits icon">
                    <h4>Fundemental limits</h4>
                    <p>
                        The vastness of the universe poses fundamental limiting problems for computing, particularly in
                        terms of expansive storage capacity. However, by exploiting the principles of superposition and
                        entanglement, quantum computing enables the development of exponentially larger memory structures
                        that overcome the limitations of classical computers.
                    </p>
                </div>
                <div class="col-lg-4 problem-item">
                    <img src="@/assets/icons/research_and_development.png" alt="Time & Power limits icon">
                    <h4>Time & Power limits</h4>
                    <p>
                        Traditional computing struggles with time and energy efficiency in solving resource-intensive
                        problems. Quantum computing, through parallel processing, offers a more energy-efficient approach,
                        addressing these challenges by quickly performing complex computations.
                    </p>
                </div>
            </div>
        </div>
    </section>
</template>

<script>
export default {
    name: 'WhyQuantumSection'
}
</script>

<style lang="scss" scoped>
section {
    padding: 3rem 0;
    margin: 10rem 0;
    background-color: #191D34;
}

.quascade-block {
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    header {
        h2 {
            font-size: 2.5rem;
            font-weight: 600;
        }

        h3 {
            color: #293156;
            font-size: 1.25rem;
            font-weight: 600;
        }
    }
}

.problem-item {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;

    img {
        width: 4rem;
        margin-bottom: 2rem;
    }

    h4 {
        margin-bottom: 1.5rem;
    }
}

.quantum-computer-img {
    max-width: 100%;
    max-height: 30rem;
    margin-top: -3rem;
}
</style>