<template>
  <section class="app-section mb-5">
    <div class="row">
      <div class="col-lg-12">
        <header>
          <h2>Size considerations for public and private keys</h2>
        </header>
        <div class="section-body">
          <p class="mb-4">Another important factor to consider is that enlarging the bit size lengthens the time for
            encrypting and
            decrypting messages, potentially leading to delayed communication.</p>
          <div class="graph">
            <Line :data="chartData" :options="chartOptions" />
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>

import {
  Chart as ChartJS,
  Title,
  Tooltip,
  Legend,
  BarElement,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement
} from 'chart.js'
import { Line } from 'vue-chartjs'

ChartJS.register(CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend, PointElement, LineElement)

export default {
  name: 'RSASizeConsiderationSection',
  components: {
    Line
  },
  computed: {
    chartData() {
      return {
        labels: [128, 256, 512, 1024, 2048, 4096],
        datasets: [{
          label: 'Encryption time (ms)',
          data: this.encryptionTimeData,
          backgroundColor: '#2ADDBA',
          borderColor: '#2ADDBA',
          tension: 0.1
        },
        {
          label: 'Decryption time (ms)',
          data: this.decryptionTimeData,
          backgroundColor: '#27AEFF',
          borderColor: '#27AEFF',
          tension: 0.1
        }]
      }
    },
    chartOptions() {
      return {
        responsive: true,
        plugins: {
          legend: {
            labels: {
              color: "white",
              font: {
                size: 14
              }
            }
          }
        },
        scales: {
          y: {
            grid: {
              color: '#3A4579',
            },
            border: {
              dash: [2, 4],
            },
            ticks: {
              color: "#FFF",
              font: {
                size: 14
              },
              beginAtZero: true
            },
            title: {
              display: true,
              color: '#FFF',
              text: 'Encryption/Decrytion time (ms)',
              font: {
                family: 'Space Grotesk',
                size: 16,
                lineHeight: 4,
              },
            }
          },
          x: {
            grid: {
              color: '#3A4579',
              borderDash: [8, 4],
            },
            border: {
              dash: [2, 4],
            },
            ticks: {
              color: "#FFF",
              font: {
                size: 14
              },
              beginAtZero: true
            },
            title: {
              display: true,
              color: '#FFF',
              text: 'Key length (bits)',
              font: {
                family: 'Space Grotesk',
                size: 16,
                lineHeight: 4,
              },
            }
          },
        }
      }
    }
  },
  data() {
    var encryptionTimeData = [0.19, 0.36, 0.57, 1.72, 3.33, 11.18];
    var decryptionTimeData = [0.29, 0.98, 5.3, 26.19, 130.84, 1116.26];

    return {
      encryptionTimeData: encryptionTimeData,
      decryptionTimeData: decryptionTimeData
    }
  }
}
</script>

<style scoped>
.graph {
  display: inline-block;
  position: relative;
  width: 1000px;
  height: 500px;
  max-width: 100%;
  max-height: 100%;
}
</style>
