<template>
    <section>
        <div class="container">
            <div class="row" style="margin-bottom: 5rem;">
                <div class="col-lg-12 quascade-block">
                    <header>
                        <h2>How does QC affect your business?</h2>
                        <p>
                            Even though solving computationally intensive optimization and simulation jobs take a long time,
                            endless budgets often yield only near-optimal results. Is it good enough?
                        </p>
                    </header>
                </div>
            </div>
            <div class="row mb-5">
                <div class="col-lg-6">
                    <img class="w-100" src="@/assets/images/6_weather.jpg" alt="Weather">
                </div>
                <div class="col-lg-6">
                    <div>
                        <h4 style="margin-bottom:2.5rem">ACCURATE MEDIUM-TERM WEATHER FORECAST</h4>
                        <ul>
                            <li class="mb-3">Why a correct medium-term weather forecast is important?</li>
                            <li>Does it only affect agriculture, construction, transportation, tourism, or even your
                                industry sector?</li>
                        </ul>
                    </div>
                </div>
            </div>

            <div class="row mb-5">
                <div class="col-lg-6">
                    <img class="w-100" src="@/assets/images/7_Opmization.jpg" alt="Optimization">
                </div>
                <div class="col-lg-6">
                    <div>
                        <h4 style="margin-bottom:2.5rem">Optimization</h4>
                        <ul>
                            <li class="mb-3">
                                Exactly how much and what kind of package can fit into a vehicle or warehouse
                                when you want to load and unload as quickly as possible?
                            </li>
                            <li>
                                Optimal pricing in the FMCG sector, taking into account price elasticity or cost volatility?
                            </li>
                        </ul>
                    </div>
                </div>
            </div>

            <div class="row mb-5">
                <div class="col-lg-6">
                    <img class="w-100" src="@/assets/images/8_NumericalSim.jpg" alt="Numerical simulation">
                </div>
                <div class="col-lg-6">
                    <div>
                        <h4 style="margin-bottom:2.5rem">Numerical Simulations</h4>
                        <ul>
                            <li class="mb-3">
                                How much drag is created by the wrong structural design?
                            </li>
                            <li>
                                How accurate is the current airflow analysis around a car, plane or even a spacecraft?
                            </li>
                        </ul>
                    </div>
                </div>
            </div>

            <div class="row mb-5">
                <div class="col-lg-6">
                    <img class="w-100" src="@/assets/images/9_other_Hasonlot_kellene_kesziteni.jpg"
                        alt="Other qc applications">
                </div>
                <div class="col-lg-6">
                    <div class="quascade-block h-100">
                        <h4 style="margin-bottom:2.5rem">And many more..</h4>
                        <p>
                            In most cases, quantum computing can result in exponential speedups and accuracy providing more
                            accurate and faster products, but not in all cases. It’s not easy to see the correct use cases
                            and domains for QC, but we help you find them for your business.

                        </p>
                    </div>
                </div>
            </div>
        </div>
    </section>
</template>

<script>
export default {
    name: 'BusinessQuantumSection'
}
</script>

<style lang="scss" scoped>
section {
    margin: 10rem 0;
    padding: 3rem 0;
}

.quascade-block {
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    header {
        h2 {
            font-size: 2.5rem;
            font-weight: 600;
        }

        h3 {
            color: #293156;
            font-size: 1.25rem;
            font-weight: 600;
        }
    }
}

li {
    list-style: none;
}

li::before {
    content: "";
    position: relative;
    left: -10px;
    top: -2px;
    width: 10px;
    height: 10px;
    display: inline-block;
    background-color: #F76400;
    border-radius: 2px;
}

img {
    border-radius: 5px;
}
</style>