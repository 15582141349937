<template>
  <section>
    <div class="container">
      <div class="illustration"></div>
      <div class="row header">
        <div class="col-sm-12 col-lg-10">
          <h1 class="mb-4">
            Ready to <span class="highlight">become quantum</span>-ready?
          </h1>
        </div>
      </div>
      <div class="row mb-2">
        <div class="col-sm-12 col-lg-8">
          <p>
            We make quantum computing simple, accessible and affordable for everyone
          </p>
          <!--
        <div class="cubes">
          <div class="cube cube1"></div>
          <div class="cube cube2"></div>
        </div>
        -->
        </div>
      </div>
      <div class="row">
        <div class="col-sm-12 col-md-9 col-lg-6 col-xl-5">
          <form class="form-area" @submit.prevent="">
            <div class="row">
              <div class="col-sm-12 col-md-8">
                <input class="form-control mr-2" id="email-address" placeholder="Enter your email address"
                  v-model="email" />
              </div>
              <div class="col-sm-6 col-md-4">
                <button type="submit" @click="this.sendEmail" class="btn">Get Started</button>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import axios from 'axios'

export default {
  name: 'WelcomeSection',
  data() {
    return {
      email: null
    }
  },
  methods: {
    async sendEmail() {
      if (!this.email) {
        return
      }

      const email_address = this.email;
      this.email = null;

      await axios.post('https://quascade-landingpage-backend.azurewebsites.net/subscribe', {
        email_address,
        "accepted_terms_and_conditions": false
      }).catch(err => {
        console.log(err);
      });
    }
  }
}
</script>

<style scoped>
section {
  padding-top: 5rem;
  padding-bottom: 7.5rem;
}

.header .highlight {
  color: #fff;
}

p {
  font-size: 1.5rem;
}

.illustration {
  position: absolute;
  width: 500px;
  height: 500px;
  top: 25%;
  right: 25%;
  background-image: url('@/assets/illustrations/illustration2.png');
  background-repeat: no-repeat;
  background-size: contain;
  background-position-x: right;
  animation: spin 5s ease-in infinite;
  filter: blur(100px) opacity(25%);
  pointer-events: none;
}

@keyframes spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}

p {
  font-size: 1.5rem;
}

.form-area {
  border: 1px solid rgb(39, 174, 255);
  border-radius: 200px;
  padding: 0rem;
}

.form-area input {
  height: 100%;
  color: #fff;
  border: none;
  padding-left: 1rem;
  background-color: transparent;
  box-shadow: none;
}

.form-area input::placeholder {
  color: #A6AABA;
}

.btn,
.btn:focus {
  width: 100%;
  color: #FFF !important;
  border-radius: 25px;
  background: rgb(39, 174, 255);
  background: linear-gradient(135deg, rgba(39, 174, 255, 1) 45%, rgba(42, 221, 186, 1) 100%);
}


.btn:hover,
.btn:active {
  color: #FFF !important;
  background: rgb(39, 174, 255);
  background: linear-gradient(135deg, rgba(39, 174, 255, 1) 0%, rgba(42, 221, 186, 1) 100%);
}


.cube {
  position: fixed;
  width: 3rem;
  height: 3rem;
  background-color: #e75c00;
  border-radius: .5rem;
}

.cube1 {
  animation: MoveUpDown1 7s linear infinite;
}

.cube2 {
  animation: MoveUpDown2 7s linear infinite;
}

@keyframes MoveUpDown1 {

  0%,
  100% {
    left: 18rem;
    top: 11rem;
  }

  50% {
    left: 19rem;
    top: 11.25rem;
  }
}

@keyframes MoveUpDown2 {

  0%,
  100% {
    left: 16rem;
    top: 9rem;
  }

  50% {
    left: 15.5rem;
    top: 8rem;
  }
}



@media (max-width: 767.98px) {
  .header-subtitle {
    max-width: 100%;
  }

  .form-area {
    border: none;
  }

  .form-area input {
    height: 3rem;
    border: 1px solid #fff;
    margin-bottom: 1rem;
  }

  .btn {
    max-width: 12rem;
    height: 3rem;
  }
}

@media (max-width: 575.98px) {
  .form-area input {
    height: 3.5rem;
  }
}
</style>