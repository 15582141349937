<template>
    <section>
        <div class="container">
            <div class="row">
                <div class="col-lg-12 quascade-block">
                    <h3>Our mission</h3>
                    <p>
                        Making quantum computing <span>simple, <br />accessible and affordable</span> for everyone
                    </p>
                </div>
            </div>
        </div>
    </section>
</template>

<script>
export default {
    name: 'MissionSection'
}
</script>

<style lang="scss" scoped>
section {
    color: #15192C !important;
    height: 110vh;
    position: relative;
    padding: 3rem 0;
    margin: 10rem 0;
    background-color: #2ADDBA;
    z-index: 1100;
}

h3 {
    color: #15192C;
    font-weight: 700;
}

p {
    font-size: 3.5rem;
    font-weight: 700;
    margin: 0;
    position: absolute;
    top: 50%;
    -ms-transform: translateY(-50%);
    transform: translateY(-50%);

    span {
        color: #FFF;
    }
}

.quascade-block {
    padding: 6rem 0;
}
</style>