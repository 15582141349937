<template>
  <section class="app-section">
    <div class="row mb-5">
      <div class="col-lg-12">
        <header>
          <h2>Live demo</h2>
        </header>
        <div class="section-body">
          <p style="margin-bottom: 0 !important;">This live demonstration showcases the RSA decryption process. It
            involves taking a
            public key as input and
            decrypting the encryption using both the classical and quantum Shor algorithms. In the case of the quantum
            algorithm, IBM's 80-qubit hardware is employed.</p>
        </div>
      </div>
    </div>

    <div class="mb-5">
      <form @submit.prevent="">
        <div class="row">
          <div class="col-md-6 col-lg-6">
            <div class="mb-4">
              <label for="public_key_textarea" class="form-label">Public key (64bit)</label>
              <textarea id="public_key_textarea" class="form-control" rows="1" v-model="public_key"
                placeholder="i.e MFwwDQYJhvc..."></textarea>
            </div>
          </div>
          <div class="col-md-6 col-lg-2">
            <div class="mb-4">
              <label for="public_exponent_textarea" class="form-label">Exponent</label>
              <input class="form-control" id="public_exponent_textarea" placeholder="65537" v-model="public_exponent" />
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-lg-8">
            <div class="mb-4">
              <label for="encrypted_message_textarea" class="form-label">Encrypted message (Base64)</label>
              <textarea class="form-control" id="encrypted_message_textarea" rows="4" v-model="encoded_text"
                placeholder="i.e hzx5R3ozfnPsuk..."></textarea>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-lg-8">
            <div class="d-flex flex-row">
              <button type="submit" @click="breakRsa" class="btn">Break RSA</button>
              <button type="submit" @click="generateRandom" class="btn btn-transparent">Generate random</button>
            </div>
          </div>
        </div>
      </form>
    </div>

    <div class="row" v-if="this.request_sent && this.response_recieved">
      <div class="col-lg-8">
        <div class="rsa-result">
          <div class="rsa-result-item">
            <div class="rsa-result-item-title">
              <div></div>
              <p>Original message</p>
            </div>
            <p class="rsa-result-item-value">{{ this.original_text }}</p>
          </div>

          <div class="rsa-result-item">
            <div class="rsa-result-item-title">
              <div></div>
              <p>Prime factors</p>
            </div>
            <p class="rsa-result-item-value">p={{ this.prime_factors[0] }} and q={{ this.prime_factors[1] }}</p>
          </div>

          <div class="rsa-result-item">
            <div class="rsa-result-item-title">
              <div></div>
              <p>Private key</p>
            </div>
            <textarea class="form-control textarea-disabled" id="public_key_textarea" rows="3" :value="this.private_key"
              disabled></textarea>
            <p class="rsa-result-item-value"></p>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import axios from 'axios'
import StringGenerator from './StringGenerator'

export default {
  name: 'DemoSection',
  data() {
    return {
      public_key: "41279026649666731",
      public_exponent: "65537",
      encoded_text: "K3+6yZ5fQQCZTXpihHFoAF1VEcrCx1IAXVURysLHUgD3EkXfuTMeAOU2mkwWfH8Agn6mGQkVBgDl+9qriaVzAJlNemKEcWgA6VGx7iLZcwCCfqYZCRUGAD2V1bzcy2YA9OXYNFePUQByyS/v5G9HAHLJL+/kb0cAPGXBRjS/RwBbn1Xu0IR7ACliZ8D05jcA",
      plain_text: "",

      request_sent: false,
      response_recieved: false,

      original_text: null,
      prime_factors: null,
      private_key: null
    }
  },
  methods: {
    async generateRandom() {
      this.plain_text = StringGenerator.generateRandomSentence();
      this.encryptText();
    },

    async encryptText() {
      this.encrypt_res = await axios.post('https://quascade-rsa-backend.azurewebsites.net/encrypt', {
        "public_key": this.public_key,
        "public_exponent": this.public_exponent,
        "text": this.plain_text,
      }).then(res => {
        console.log(res)
        this.encoded_text = res.data.encoded_message;
        this.plain_text = res.data.original_message;
      }).catch(error => {
        console.log(error)
      })
    },

    async breakRsa() {
      this.request_sent = true;
      this.rsabreak_res = await axios.post('https://quascade-rsa-backend.azurewebsites.net/break', {
        "public_key": this.public_key,
        "public_exponent": this.public_exponent,
        "text": this.encoded_text,
      }).then(res => {
        console.log(res)
        this.original_text = res.data.original_text;
        this.prime_factors = res.data.prime_factors;
        this.private_key = res.data.private_key;
        this.response_recieved = true;

      }).catch(error => {
        console.log(error)
      })

    }
  }
}
</script>

<style scoped>
img {
  width: 75%;
}

label {
  color: #FFF;
}

.rsa-result {
  display: flex;
  flex-direction: column;
}

.rsa-result-item:not(:last-child) {
  margin-bottom: 2rem !important;
}

.rsa-result-item-title {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.rsa-result-item-title div {
  width: 8px;
  height: 8px;
  background-color: #2ADDBA;
  margin-right: .5rem;
  border-radius: 2px;
}

.rsa-result-item-title p {
  font-weight: bold;
  margin-bottom: 0.2rem !important;
}

.rsa-result-item-value {
  color: #9FABD1;
  margin-bottom: 0rem !important;
  padding-left: 1rem;
}

.textarea-disabled {
  margin-top: .2rem;
  margin-left: 1rem;
  width: calc(100% - 1rem)
}

input,
textarea {
  color: #A6AABA !important;
  background-color: #293156 !important;
  border: none !important;
  resize: none !important;
}

input::placeholder,
textarea::placeholder {
  color: #606996 !important;
}

input:focus,
textarea:focus {
  box-shadow: 0 0 0 0.15rem rgb(34 47 84) !important;
}

.textarea-disabled {
  background-color: #191D34 !important;
}
</style>
