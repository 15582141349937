<template>
  <section class="app-section">
    <div class="row">
      <div class="col-lg-12">
        <header>
          <h2>RSA break time</h2>
        </header>
        <div class="section-body">
          <p class="mb-4">The figure below illustrates the time required to break RSA based on its bit length. Notably,
            quantum
            computation sees a linear increase in time, whereas the classical method experiences an exponential growth in
            comparison.</p>
          <div class="graph">
            <Line :type="type" :data="chartData" :options="chartOptions" />
            <div class="row">
              <div class="col-lg-8">
                <div class="d-flex flex-row">
                  <button type="submit" @click="expand" class="btn btn-transparent">Expand</button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    
  </section>
</template>

<script>

import {
  Chart as ChartJS,
  Title,
  Tooltip,
  Legend,
  BarElement,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
} from 'chart.js'
import { Line } from 'vue-chartjs'

ChartJS.register(CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend, PointElement, LineElement)

export default {
  name: 'RSABreakTimeSection',
  components: {
    Line
  },
  computed: {
    type() {
      return "line"
    },
    chartData() {
      return {
        labels: [128, 256, 512, 1024, 2048],
        datasets: [{
          label: 'Break time quantum (s)',
          data: this.breakTimeQuantum,
          fill: false,
          backgroundColor: '#2ADDBA',
          borderColor: '#2ADDBA',
          tension: 0.1
        },
        {
          label: 'Break time classic (s)',
          data: this.breakTimeClassic,
          fill: false,
          backgroundColor: '#27AEFF',
          borderColor: '#27AEFF',
          tension: 0.1
        }]
      }
    },
    chartOptions() {
      return {
        responsive: true,
        plugins: {
          legend: {
            labels: {
              color: "white",
              font: {
                size: 14
              }
            }
          }
        },
        scales: {
          y: {
            grid: {
              color: '#3A4579',
              borderDash: [8, 4],
            },
            border: {
              dash: [2, 4],
            },
            ticks: {
              color: "#FFF",
              font: {
                size: 14
              },
              beginAtZero: true
            },
            title: {
              display: true,
              color: '#FFF',
              text: 'Time to break public key (s)',
              font: {
                family: 'Space Grotesk',
                size: 16,
                lineHeight: 4,
              },
            }
          },
          x: {
            grid: {
              color: '#3A4579',
              borderDash: [8, 4],
            },
            border: {
              dash: [2, 4],
            },
            ticks: {
              color: "#FFF",
              font: {
                size: 14
              },
              beginAtZero: true
            },
            title: {
              display: true,
              color: '#FFF',
              text: 'Key length (bits)',
              font: {
                family: 'Space Grotesk',
                size: 16,
                lineHeight: 4,
              },
            }
          }
        }
      }
    }
  },
  data() {
    var breakTimeQuantumComplete = [5, 10, 15, 20, 25];
    var breakTimeClassicComplete = [5, 10, 100, 1000, 10000];
    var sampleSize = 3;
    return {
      sampleSize,
      breakTimeQuantumComplete: [5, 10, 15, 20, 25],
      breakTimeClassicComplete: [5, 10, 100, 1000, 10000],
      breakTimeQuantum: breakTimeQuantumComplete.slice(0, sampleSize),
      breakTimeClassic: breakTimeClassicComplete.slice(0, sampleSize)
    }
  },
  methods: {
    expand() {
      console.log(this.sampleSize)
      if (this.sampleSize < 5) {
        this.sampleSize += 1;
      }
      else {
        this.sampleSize = 3;
      }
      this.breakTimeQuantum = this.breakTimeQuantumComplete.slice(0, this.sampleSize);
      this.breakTimeClassic = this.breakTimeClassicComplete.slice(0, this.sampleSize);
    }
  }

}
</script>

<style scoped>
.graph {
  display: inline-block;
  position: relative;
  width: 1000px;
  height: 500px;
  max-width: 100%;
  max-height: 100%;
}
</style>
