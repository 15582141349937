<template>
  <section class="app-section">
    <div class="row">
      <div class="col-lg-12">
        <header>
          <h2>RSA Encryption</h2>
        </header>
        <div class="section-body">
          <p>RSA encryption is a method to keep information secure when it's transmitted over the internet.
            It uses a pair
            of keys: one public key for encryption and one private key for decryption. Only the person with the private
            key
            can unlock the encrypted data. This technology is crucial for online shopping, email security, and protecting
            sensitive information, making it an essential part of keeping our digital world safe.</p>
          <div class="rsa-visualized d-flex justify-content-center">
            <img src="@/assets/images/mitm.png" alt="Man-in-the-middle attack">
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  name: 'RSAEncryptionSection',
}
</script>

<style scoped>
img {
  width: 75%;
}

.rsa-visualized {
  margin-top: 5rem;
}
</style>
