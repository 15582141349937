<template>
  <HeaderNav />
  <div class="main">
    <router-view></router-view>
  </div>
  <FooterNav />
</template>

<script>
import HeaderNav from './components/navigation/HeaderNav.vue'
import FooterNav from './components/navigation/FooterNav.vue'

export default {
  name: 'App',
  components: {
    HeaderNav,
    FooterNav
  }
}
</script>

<style>
@import url('https://fonts.googleapis.com/css2?family=Space+Grotesk:wght@300;400;500;600;700&display=swap');

#app {
  min-height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
}

.main {
  width: 100%;
  padding-top: 7.5rem;
}
</style>
