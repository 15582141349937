<template>
    <section>
        <div class="container">
            <div class="row">
                <div class="col-lg-6 quascade-block">
                    <header>
                        <h3>Problem</h3>
                        <h2>Quantum computing is hard</h2>
                    </header>
                    <div>
                        <p>
                            Harnessing the power of quantum computing requires expertise in multiple specialised fields and
                            need to be effectively combined with classical algorithms for a full system integration.
                        </p>
                    </div>
                </div>
                <div class="col-lg-6">
                    <div class="qc-problem">
                        <img src="@/assets/images/qc-difficult.png" />
                    </div>
                </div>
            </div>
        </div>
    </section>

    <section>
        <div class="container">
            <div class="row">
                <div class="col-lg-6 quascade-block">
                    <header>
                        <h3>Solution</h3>
                        <h2>Unless someone simplifies it</h2>
                    </header>
                    <div>
                        <p>
                            However, by leveraging our expertise and building on existing hardware infrastructure, our
                            customers can overcome these barriers and become quantum ready in significantly less time.
                        </p>
                    </div>
                </div>
                <div class="col-lg-6 qc-solution">
                    <video src="@/assets/videos/piquasso_real_time_sharing.mp4" loop muted autoplay></video>
                </div>
            </div>
        </div>
    </section>
</template>

<script>
export default {
    name: 'ProblemSection'
}
</script>

<style lang="scss" scoped>
section {
    margin: 10rem 0;
}

.quascade-block {
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    header {
        h2 {
            font-size: 2.5rem;
            font-weight: 600;
        }

        h3 {
            color: #293156;
            font-size: 1.25rem;
            font-weight: 600;
        }
    }
}

.qc-problem {
    img {
        width: 100%;
    }
}

.qc-solution {
    video {
        width: 100%;
        border-radius: 10px;
    }
}
</style>