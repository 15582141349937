const string_constants = ["The sun rises in the east.",
  "Apples are sweet and crunchy.",
  "Dogs are known as man’s best friend.",
  "The world is full of beauty.",
  "Music can soothe the soul.",
  "Reading expands our horizons.",
  "Kindness is a universal language.",
  "Laughter is the best medicine.",
  "Patience is a virtue.",
  "Every cloud has a silver lining.",
  "Honesty is the best policy.",
  "Time waits for no one.",
  "Actions speak louder than words.",
  "Beauty lies in the eyes of the beholder.",
  "Health is wealth.",
  "The early bird catches the worm.",
  "Practice makes perfect.",
  "Rome wasn’t built in a day.",
  "Where there’s smoke, there’s fire.",
  "The pen is mightier than the sword.",
  "A journey of a thousand miles begins with a single step.",
  "Every man is the architect of his own fortune.",
  "Fortune favors the brave.",
  "Great minds think alike.",
  "Ignorance is bliss.",
  "It’s never too late to learn.",
  "Knowledge is power.",
  "Look before you leap.",
  "Might makes right.",
  "No pain, no gain.",
  "Opportunity seldom knocks twice.",
  "Prevention is better than cure.",
  "Seeing is believing.",
  "The grass is always greener on the other side.",
  "Unity is strength.",
  "Variety is the spice of life.",
  "Well begun is half done.",
  "You can’t judge a book by its cover.",
  "Absence makes the heart grow fonder.",
  "Birds of a feather flock together.",
  "Charity begins at home.",
  "Don’t count your chickens before they hatch.",
  "Failure is the stepping stone to success.",
  "God helps those who help themselves.",
  "Home is where the heart is.",
  "It’s better to be safe than sorry.",
  "Justice delayed is justice denied.",
  "Love is blind.",
  "Necessity is the mother of invention.",
  "Out of sight, out of mind.",
  "Practice what you preach.",
  "Slow and steady wins the race.",
  "Time and tide wait for no man.",
  "Where there’s a will, there’s a way.",
  "You can lead a horse to water, but you can’t make it drink.",
  "All that glitters is not gold.",
  "Beggars can’t be choosers.",
  "Don’t put all your eggs in one basket.",
  "Every dog has its day.",
  "Good things come to those who wait.",
  "It’s a piece of cake.",
  "Keep your friends close and your enemies closer.",
  "Look on the bright side.",
  "No man is an island.",
  "Once bitten, twice shy.",
  "Still waters run deep.",
  "There’s no place like home.",
  "When in Rome, do as the Romans do.",
  "You can’t have your cake and eat it too.",
  "Beauty is in the eye of the beholder.",
  "Don’t judge a book by its cover.",
  "He who laughs last, laughs longest.",
  "It’s not over till the fat lady sings.",
  "Money can’t buy happiness.",
  "Never look a gift horse in the mouth.",
  "Out of the frying pan and into the fire.",
  "There’s no smoke without fire.",
  "When the cat’s away, the mice will play.",
  "You can’t make an omelette without breaking eggs.",
  "All’s well that ends well.",
  "Better late than never.",
  "Don’t count your chickens before they’re hatched.",
  "Every man for himself.",
  "He who dares, wins.",
  "It’s the thought that counts.",
  "Let sleeping dogs lie.",
  "Never say never.",
  "Opportunity knocks but once.",
  "The proof of the pudding is in the eating.",
  "There’s no such thing as a free lunch.",
  "A stitch in time saves nine.",
  "Curiosity killed the cat.",
  "Don’t bite the hand that feeds you.",
  "Every picture tells a story.",
  "Fortune favors the bold.",
  "If it ain’t broke, don’t fix it.",
  "Keep your chin up.",
  "Love conquers all.",
  "The apple doesn’t fall far from the tree.",
  "You can’t teach an old dog new tricks."]

export default {
  generateRandomSentence() {
    var ind = Math.floor(Math.random() * string_constants.length)
    var plain_text = string_constants[ind]
    return plain_text
  }
}