<template>
  <nav class="navbar navbar-default fixed-top">
    <div class="container d-none d-md-block">
      <div class="row">
        <div class="col-lg-12">
          <header class="header d-flex justify-content-between">
            <div class="header-logo">
              <router-link to="/">
                <img src="@/assets/logo.png" alt="Quascade logo">
              </router-link>
            </div>
            <div class="d-flex header-items align-items-center">
              <router-link to="/">Home</router-link>
              <!--<router-link to="/solutions">Solutions</router-link>-->
              <router-link to="/about">About us</router-link>
              <router-link to="/contact">Contact</router-link>
            </div>
          </header>
        </div>
      </div>
    </div>
  </nav>
</template>

<script>
export default {
  name: 'HeaderNav'
}
</script>

<style>
.navbar {
  background-color: #15192C;
  z-index: 1100;
}

.header-items a {
  color: inherit;
  font-weight: 600;
  text-decoration: none;
}

.header-items a:not(:last-child) {
  margin-right: 2rem
}

.header-items a:last-child {
  color: #FFF;
  background: rgb(39, 174, 255);
  background: linear-gradient(135deg, rgba(39, 174, 255, 1) 45%, rgba(42, 221, 186, 1) 100%);
  padding: .4rem 1.75rem;
  border-radius: 5rem;
}

.header-items a:last-child:hover {
  background-color: #e75c00;
}

.header-logo {
  width: 200px;
  margin-left: -1.25rem;
}

.header-logo img {
  width: 100%;
}
</style>
