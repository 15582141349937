<template>
  <div class="quascade-landing-page">
    <WelcomeSection />
    <AccelerateJourneySection />
    <WhyQuantumSection />
    <ProblemSection />
    <BusinessQuantumSection />
    <MissionSection />
    <OurServicesSection />
    <SolutionsSection />
    <OurTeam />
  </div>
</template>

<script>
import WelcomeSection from '../sections/WelcomeSection.vue'
import AccelerateJourneySection from '../sections/AccelerateJourneySection.vue'
import WhyQuantumSection from '../sections/WhyQuantumSection.vue'
import ProblemSection from '../sections/ProblemSection.vue'
import BusinessQuantumSection from '../sections/BusinessQuantumSection.vue'
import MissionSection from '../sections/MissionSection.vue'
import OurServicesSection from '../sections/OurServicesSection.vue'
import SolutionsSection from '../sections/SolutionsSection.vue'
import OurTeam from '../sections/OurTeam.vue'

export default {
  name: 'LandingPage',
  components: {
    WelcomeSection,
    AccelerateJourneySection,
    WhyQuantumSection,
    ProblemSection,
    BusinessQuantumSection,
    MissionSection,
    OurServicesSection,
    SolutionsSection,
    OurTeam
  }
}
</script>

<style scoped></style>