<template>
  <section class="container">
    <div class="row">
      <div class="col-lg-2">
      </div>
      <div class="col-lg-10">
        <RSAEncryptionSection />
        <DemoSection />
        <RSABreakTimeSection />
        <RSASizeConsiderationSection />
        <hr />
        <div class="d-flex justify-content-center align-center mb-4">
          <p style="margin-right:1rem; margin-bottom: 0rem;">In collaboration with</p>
          <img style="max-width: 5rem;" src="@/assets/qan_logo.png" alt="QAN logo">
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import RSAEncryptionSection from '@/components/rsa/RSAEncryptionSection.vue'
import DemoSection from '@/components/rsa/DemoSection.vue'
import RSABreakTimeSection from '@/components/rsa/RSABreakTimeSection.vue'
import RSASizeConsiderationSection from '@/components/rsa/RSASizeConsiderationSection.vue'

export default {
  name: 'RSAPage',
  components: {
    RSAEncryptionSection,
    DemoSection,
    RSABreakTimeSection,
    RSASizeConsiderationSection
  }
}
</script>

<style scoped>
.logos img {
  position: fixed;
  top: 2rem;
  max-width: 8rem;
  margin-bottom: 1rem;
}
</style>



curl -s --user 'd7a48a6935cd6e87da29837b1d94050d-8c9e82ec-ce28c93a' \
    https://api.mailgun.net/v3/quascade/messages \
    -F from='hello@quascade.com' \
    -F to=joczik@gmail.com \
    -F subject='Hello' \
    -F text='Testing some Mailgun awesomeness!'