import { createWebHistory, createRouter } from "vue-router";
import LandingPage from "@/pages/LandingPage.vue";
import RSAPage from "@/pages/RSAPage.vue"

import OurTeam from "@/sections/OurTeam.vue"

const routes = [
    {
        path: "/",
        name: "root",
        component: LandingPage,
    },
    {
        path: "/about",
        name: "About",
        component: OurTeam,
    },
    {
        path: "/rsa",
        name: "RSA",
        component: RSAPage
    }
];

const router = createRouter({
    history: createWebHistory(),
    routes,
});

export default router;