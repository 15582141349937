<template>
  <footer>
    <div class="container d-none d-md-block">

      <div class="row mb-5">
        <div class="col-lg-12">
          <div class="header-logo">
            <router-link to="/">
              <img src="@/assets/logo_indigo.png" alt="Quascade logo">
            </router-link>
          </div>
        </div>
      </div>

      <div class="row mb-5">
        <div class="col-lg-3">
          <h5>About Quascade</h5>
          <ul>
            <li><a href="#">Home</a></li>
            <li><a href="#">Get in touch</a></li>
            <li><a href="#">FAQs</a></li>
          </ul>
        </div>
        <div class="col-lg-3">
          <h5>Products</h5>
          <ul>
            <li><a href="#">Testiomonials</a></li>
            <li><a href="#">How it works</a></li>
          </ul>
        </div>
        <div class="col-lg-6">
          <h5>Ready to become quantum-ready?</h5>
          <form @submit.prevent="">
            <div class="row align-items-end">
              <div class="col-lg-8 d-flex flex-column">
                <label class="mb-2" for="subscribe">Join our community</label>
                <input id="subscribe" type="text" placeholder="Enter your email" v-model="email">
              </div>
              <button class="col-lg-2 btn" @click="this.sendEmail">Subscribe</button>
            </div>

          </form>
        </div>
      </div>

      <div class="row">
        <div class="col-lg-12 d-flex flex-column align-items-center">
          <p>Follow us on social media</p>
          <div class="d-flex flex-row social-media">
            <a href="#">F</a>
            <a href="#">L</a>
            <a href="#">I</a>
            <a href="#">T</a>
          </div>
        </div>
      </div>
    </div>
  </footer>
</template>

<script>
import axios from 'axios'

export default {
  name: 'HeaderNav',
  data() {
    return {
      email: null
    }
  },
  methods: {
    async sendEmail() {
      if (!this.email) {
        return
      }

      const email_address = this.email;
      this.email = null;

      await axios.post('https://quascade-landingpage-backend.azurewebsites.net/subscribe', {
        email_address,
        "accepted_terms_and_conditions": false
      }).catch(err => {
        console.log(err);
      });
    }
  }
}
</script>

<style scoped>
footer {
  width: 100%;
  color: #15192C;
  background-color: #2ADDBA;
  padding: 2rem 0;
}

footer p,
footer a {
  font-size: 18px !important;
}

.header-items a {
  color: inherit;
  font-weight: 600;
  text-decoration: none;
}


.header-items a:not(:last-child) {
  margin-right: 2rem
}

.header-items a:last-child {
  color: #FFF;
  background-color: #F76400;
  padding: .4rem 1.75rem;
  border-radius: 5rem;
}

.header-items a:last-child:hover {
  background-color: #e75c00;
}

.header-logo {
  width: 300px;
  margin-left: -2rem;
}

.header-logo img {
  width: 100%;
}

h5 {
  font-weight: 700;
  text-transform: uppercase;
  margin-bottom: .75rem;
}

ul {
  padding-left: 0;
}

li {
  list-style: none;
  margin-bottom: .25rem;
}

li a {
  color: #15192C;
  text-decoration: none;
}

.social-media a {
  width: 45px;
  height: 45px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #FFF;
  text-decoration: none;
  margin: 0 .5rem;
  text-align: center;
  border-radius: 100%;
  background-color: #15192C;
}

input {
  height: 2.5rem;
  padding: 0 .75rem;
  border-radius: 10px;
  border: none;
  box-shadow: none !important;
}

.btn {
  color: #FFF !important;
  height: 100%;
  background-color: #15192C !important;
}
</style>
