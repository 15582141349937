import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import VueGtag from 'vue-gtag'

import "bootstrap/dist/css/bootstrap.min.css"
import "bootstrap"
import "./main.css"

const app = createApp(App)
app.use(router)
app.use(VueGtag, {
    appName: 'Quascade - Landing page',
    pageTrackerScreenviewEnabled: true,
    config: {
        id: "G-P2WRHYH05H"
    }
})
app.mount('#app')

